<template>
  <div class="row mx-0">
    <div class="col-lg-6 col-md-12 col-12 d-none d-lg-block px-0">
      <div class="reg-modal-Img">
        <img src="@/assets/Images/modal-signup.jpg" alt="" class="img-fluid" />
      </div>
    </div>

    <div class="innerCol col-lg-6 col-md-12 col-12 px-3 py-4">
      <div class="close-reg-modal mb-2 text-end">
        <button
          type="button"
          class="close-reg-button btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="handleClose"
        ></button>
      </div>
      <div class="regForm px-3">
        <Form
          aria-autocomplete="false"
          autocomplete="false"
          @submit="signUpUser(credentials)"
          :validation-schema="schema"
          v-slot="{ errors }"
        >
          <h3>Join Us!</h3>
          <div class="inputBox my-2 px-3 text-left">
            <Field
              type="text"
              class="reg-input"
              placeholder="Name"
              v-model="credentials.name"
              name="name"
            />
          </div>
          <span class="text-danger">{{ errors.name }}</span>
          <div v-if="error.errors.name" class="text-danger">
            {{ error.errors.name.toString() }}
          </div>

          <div class="inputBox my-2 px-3 text-left">
            <Field
              type="text"
              class="reg-input"
              placeholder="Email"
              v-model="credentials.email"
              name="email"
              autocapitalize="false"
              aria-autocomplete="false"
            />
          </div>
          <span class="text-danger">{{ errors.email }}</span>
          <div v-if="error.errors.email" class="text-danger">
            {{ error.errors.email.toString() }}
          </div>

          <div class="inputBox my-2 px-3 text-left">
            <Field
              type="text"
              class="reg-input"
              placeholder="Mobile"
              v-model="credentials.mobile"
              name="mobile"
            />
          </div>
          <span class="text-danger">{{ errors.mobile }}</span>
          <div v-if="error.errors.mobile" class="text-danger">
            {{ error.errors.mobile.toString() }}
          </div>

          <div class="inputBox my-2 px-3 text-left">
            <password
              name="password"
              class="reg-input password-field"
              v-model="credentials.password"
              placeholder="Password"
            />
          </div>
          <span class="text-danger">{{ errors.password }}</span>
          <div v-if="error.errors.password" class="text-danger">
            {{ error.errors.password.toString() }}
          </div>

          <div class="inputBox my-2 px-3 text-left">
            <password
              name="c_password"
              class="reg-input password-field"
              v-model="credentials.c_password"
              placeholder="Confirm Password"
            />
          </div>
          <span class="text-danger">{{ errors.c_password }}</span>
          <div v-if="error.errors.c_password" class="text-danger">
            {{ error.errors.c_password.toString() }}
          </div>

          <div class="reg-modal-button my-2">
            <button type="submit" class="btn btn-primary">SIGN UP</button>
          </div>
          <p>
            Already have an account?
            <span @click="showLogin">Log In</span>
          </p>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onBeforeUnmount } from "vue";
import { Form, Field } from "vee-validate";
import Password from "@/components/Input/Password.vue";
import * as Yup from "yup";

import HelperFunction from "@/common/helpers";
import { useAuthStore } from "@/store/useAuth";
import { useErrorStore } from "@/store/useError";

export default defineComponent({
  name: "registration-form",
  components: {
    Form,
    Field,
    Password,
  },
  emits: ["closeModal", "isLoading"],
  setup(props, { emit }) {
    const loading = ref(false);
    const { showToast } = HelperFunction();
    const error = useErrorStore();

    const credentials = ref({});

    const handleClose = () => {
      emit("closeModal", { showModal: false, formSubmit: false });
    };

    //VALIDATION
    const schema = Yup.object().shape({
      name: Yup.string().required(),
      email: Yup.string().required(),
      mobile: Yup.string()
        .required("This field is Required")
        .matches(/^(?:(?:\+|00)88|01)?\d{11}$/, "Phone number is not valid"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
      c_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    });

    const showLogin = () => {
      emit("closeModal", {
        nextComp: "LoginForm",
        showModal: true,
        formSubmit: false,
      });
    };

    // onMounted(() => {
    // console.log($config)
    // })

    //ACTION REQUEST
    const signUpUser = () => {
      emit("isLoading", { isLoading: true, label: "Signing up ..." });
      loading.value = !loading.value;

      useAuthStore()
        .register(credentials.value)
        .then(() => {
          emit("isLoading", false);
          emit("closeModal", {
            status: 200,
            message: "Success",
            nextComp: "OTPForm",
            showModal: true,
            formSubmit: true,
          });

          showToast({
            status: 200,
            data: { message: "Successfully Logged In." },
          });
        })
        .catch(() => {
          emit("isLoading", false);
          loading.value = !loading.value;

          showToast({ status: error.status, data: { message: error.message } });
        });
    };

    onBeforeUnmount(() => error.$reset());

    return {
      error,
      schema,
      credentials,
      handleClose,
      showLogin,
      signUpUser,
    };
  },
});
</script>