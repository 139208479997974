<template>
    <div class="inputBox d-flex align-items-center  my-2 px-3">
        <Field 
            :type="dataList.isPassword ? 'password' : 'text'"
            :value="value"
            v-bind="$attrs"
            @input="$emit('input', $event.target.value)"
        />
        <span 
            @click="toggle"
            style="cursor: pointer"
        >
            <i v-if="dataList.isPassword" class="fas fa-eye-slash"></i>
            <i v-if="!dataList.isPassword" class="fas fa-eye"></i>
        </span>
    </div>
</template>

<script>
import {Field} from "vee-validate";
import {defineComponent, reactive} from "vue";

export default defineComponent({
    name: "input-password",
    inheritAttrs: false,
    components: {Field},
    props: {
        value: {
            type: String,
            required: false
        }
    },
    setup() {
        let dataList = reactive({
            isPassword: true,
            icon: 'fas fa-eye'
        });

        function toggle() {
            dataList.isPassword = !dataList.isPassword;
            dataList.icon = dataList.isPassword ? 'far fa-eye-slash' : 'fas fa-eye';
        }

        return {
            dataList,
            toggle
        }
    }
})
</script>
