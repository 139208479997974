<template>
  <!-- /* Footer section start */ -->
  <!-- /* Footer section start */ -->
  <footer>
    <div class="custom-container container">
      <div class=" footer-copyright py-1">
          <span class="mb-0 text-white ">&copy; Copyright 2022 CWCM. All Rights Reserved.</span>
      </div>
    </div>
  </footer>

  <!-- <footer class="py-4">
      <div class="custom-container container my-4">
          <router-link class="navbar-brand" to="/">
              <img src="@/assets/Images/CWCM-logo.svg" alt="" class="img-fluid">
          </router-link>
          <div class="footer-copyright mt-4">
              <p class="mt-4 mb-4 text-white">&copy; Copyright 2022 All Rights Reserved.</p>
          </div>
      </div>
  </footer> -->
  <!-- /* Footer section end  */ -->
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: 'footer-section' 
})
</script>