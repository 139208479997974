<template>
  <div class="login p-3">
    <div class="close-reg-modal mb-2 text-end">
      <button
        type="button"
        class="close-reg-button btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        @click="handleClose"
      ></button>
    </div>
    <div class="regForm px-3 pt-3">
      <Form @submit="forgetPassword">
        <h3>Forget Password</h3>
        <div class="inputBox my-2 px-3 text-left">
          <input
            class="reg-input"
            type="text"
            v-model="credentials.email"
            id="email"
            placeholder="Email"
            :class="error.errors && error.errors.email ? 'is-invalid' : ''"
            :disabled="loading"
          />
        </div>
        <div
          v-if="error.errors && error.errors.email"
          class="invalid-feedback fw-bolder"
        >
          {{ error.errors && error.errors.email.toString() }}
        </div>

        <div class="reg-modal-button mt-4 mb-2">
          <button type="submit" :disabled="loading" class="btn btn-primary">
            Send Reset Link
          </button>
        </div>
        <p>
          Don't have an account?
          <span @click="showSignIn">Sign in</span>
        </p>
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { Form } from "vee-validate";
import HelperFunction from "@/common/helpers";

import { useAuthStore } from "@/store/useAuth";
import { useErrorStore } from "@/store/useError";

export default defineComponent({
  name: "forget-password",
  emits: ["closeModal", "isLoading"],
  components: {
    Form,
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const credentials = ref({});
    const auth = useAuthStore();
    const error = useErrorStore();
    const { showToast } = HelperFunction();

    const handleClose = () => {
      emit("closeModal", { showModal: false, formSubmit: false });
    };

    const showSignIn = () => {
      emit("closeModal", {
        nextComp: "LoginForm",
        showModal: true,
        formSubmit: false,
      });
    };

    const forgetPassword = () => {
      emit("isLoading", { isLoading: true, label: "Forget password!" });
      loading.value = !loading.value;

      auth
        .forget(credentials.value)
        .then(() => {
          showToast({ status: 200, data: { message: auth.message } });
        })
        .catch(() => {
          showToast({ status: error.status, data: { message: error.message } });
        })
        .finally(() => {
          emit("isLoading", false);
          loading.value = !loading.value;
        });
    };

    return {
      loading,
      credentials,
      error,
      handleClose,
      showSignIn,
      forgetPassword,
    };
  },
});
</script>