import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from "@/store/useAuth";

const routes = [
  {
    path: '/',
    component: require('@/views/layouts/index.vue').default,
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/HomeView.vue')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/AboutView.vue')
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/ContactView.vue')
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/auth/ProfileView.vue'),
        meta: { requireAuth: true },
      },
      {
        path: '/auction/:id',
        name: 'auction',
        component: () => import('@/views/SingleAuction.vue'),
        props: true
      },
      {
        path: '/password/reset/:id',
        name: 'reset',
        component: () => import('@/views/guest/ResetPassword.vue'),
        props: true
      },
      {
        path: '/auctions',
        name: 'auctions',
        component: () => import('@/views/AllAuctions.vue')
      },
      {
        path: '/commodity/auctions/:id/:commodityname',
        name: 'commodityauctions',
        component: () => import('@/views/CommodityAuctions.vue')
      },
      {
        path: "/:pathMatch(.*)*",
        component: () => import("@/views/404.vue"),
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth && !useAuthStore().loggedIn)
    next({ name: "home" });
  else next();
});

export default router
