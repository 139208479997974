<template>
  <router-view />
</template>

<script>
  import { defineComponent, onBeforeMount } from "vue";

  import { useAuthStore } from "@/store/useAuth";
  import { useErrorStore } from "@/store/useError";

  export default defineComponent({
    name: "app",
    setup() {
      const auth = useAuthStore();
      const error = useErrorStore();

      onBeforeMount(() => {
        auth
          .verifyAuth()
      })

      return {
        auth,
        error
      }
    }
  })
</script>

<style>
</style>
