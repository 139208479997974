// import { inject } from "vue"
import moment from "moment";
import { useToast } from 'vue-toastification'
import BidToasts from "@/components/BidToast.vue";
import lodash from 'lodash';

export default () => {
  // const toast = inject('toast')
  const toast = useToast()


  const showToast = (info, props = false) => {
    if (props) {
      toast({
        component: BidToasts,
        props: {
          data: info  // Optional
        },
      }, {
        toastClassName: "my-custom-toast-class",
        timeout: 2000,
        position: "bottom-left"
      })
    } else {
      const options = {
        timeout: 2000,
        position: "bottom-right"
      }

      //SUCCESS
      if (info.data && (info.status === 201 || info.status === 200)) {
        toast.success(info.data.message, options)
      } else {
        //ERRORS
        if (info.response && info.response.data.errors && (info.response.status >= 400 && info.response.status < 500)) {
          // SHOW BACKEND ERRORS
          const errorKVP = info.response.data ? info.response.data.errors : [];

          const errors = Object.values(errorKVP)
          errors.forEach(error => {
            toast.error(error.toString(), options)
          })
        } else if (info.response && info.response.status === 500) {
          toast.error(info.response.data ? info.response.data.message : info.response.statusText, options)
        } else {
          toast.warning(info.data.message, options)
        }
      }
    }

  }

  const dateExpired = (value) => {
    return new Date(), moment(new Date()).isAfter(value)
  }

  const currencyFormat = (value) => {
    return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'bdt' }).format(value)
  }

  const currencyFormatWithoutSymbol = (value) => {
    return new Intl.NumberFormat('en-GB').format(value)
  }

  const isEmpty = (obj) => {
    return lodash.isEmpty(obj);
  }

  return {
    showToast,
    dateExpired,
    currencyFormat,
    currencyFormatWithoutSymbol,
    isEmpty
  }
}