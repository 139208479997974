import { defineStore } from "pinia";
import axios from "axios";
import lodash from 'lodash';

export const useAuthStore = defineStore("auth", {
  state: () => ({
    loggedIn: localStorage.getItem("token") ? true : false,
    message: null,
    user: null,
    districts: [],
    commodities: [],
    bidHistory: [],
    bidHistoryNextPageUrl: null
  }),

  getters: {},

  actions: {
    async login(credentials) {
      const response = (await axios.post(window.vueConfig.Auth.LoginEndPoint, credentials)).data;

      if (response) {
        const access_token = `${response.data.token_type} ${response.data.access_token}`;
        const refresh_token = `${response.data.token_type} ${response.data.refresh_token}`;

        localStorage.setItem("token", access_token);
        localStorage.setItem("refresh_token", refresh_token);
        axios.defaults.headers.common["Authorization"] = access_token;

        this.message = response.message

        await this.fetchUser();

        await this.getBidHistory();
      }
    },

    async forget(credentials) {
      const response = (await axios.post(window.vueConfig.Auth.ForgetPassword, credentials)).data;
      if (response) {
        this.message = response.message
      }
    },

    async reset(credentials) {
      const response = (await axios.post(window.vueConfig.Auth.ResetPassword, credentials)).data;
      if (response) {
        this.message = response.message
      }
    },

    async logout() {
      const response = (await axios.post("logout")).data;

      if (response) {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");

        this.$reset();
      }
    },

    async register(credentials) {
      const response = (await axios.post(window.vueConfig.Auth.signUpEndPoint, credentials)).data;

      if (response) {
        const access_token = `${response.data.token_type} ${response.data.access_token}`;
        const refresh_token = `${response.data.token_type} ${response.data.refresh_token}`;

        localStorage.setItem("token", access_token);
        localStorage.setItem("refresh_token", refresh_token);
        axios.defaults.headers.common["Authorization"] = access_token;

        this.message = response.message

        await this.fetchUser();
      }
    },

    async fetchUser() {
      const response = (await axios.get(window.vueConfig.User.ProfileEndPoint)).data;

      this.user = response.data.user
      this.districts = response.data.districts
      this.commodities = response.data.commodities

      this.loggedIn = true;
    },

    async getBidHistory() {
      let url = !lodash.isEmpty(this.bidHistoryNextPageUrl) ? this.bidHistoryNextPageUrl : window.vueConfig.User.BidHistory;
      const response = (await axios.get(url)).data;
      const resData = response.data;
      this.bidHistoryNextPageUrl = resData.next_page_url;
      this.bidHistory = [...this.bidHistory, ...resData.data];
    },

    async verifyOtp(values) {
      const response = (await axios.post(window.vueConfig.Verify.OTPEndPoint, values)).data;

      this.message = response.message

      await this.fetchUser();
    },

    async updateAvater(values) {
      const config = {
        header: { "content-type": "multipart/form-data" }
      };

      const response = await (axios.post(window.vueConfig.User.UpdateAvaterEndPoint, values, config))
      if (response)
        await this.fetchUser();
    },

    async updateProfile(values) {
      const response = await (axios.post(window.vueConfig.User.UpdateProfileEndPoint, values))

      if (response)
        await this.fetchUser();
    },

    async updatePassword(values) {
      const response = await (axios.post(window.vueConfig.User.UpdatePasswordEndPoint, values))

      if (response)
        await this.logout();
    },

    async verifyAuth() {
      if (localStorage.getItem("token")) {
        await axios.get(window.vueConfig.Verify.TokenEndPoind)
          .then(async () => {
            await this.fetchUser();
          })
          .catch(async () => {
            axios.defaults.headers.common["Authorization"] = ""

            const unInterceptedAxiosInstance = axios.create()

            unInterceptedAxiosInstance.defaults.headers.common["Authorization"] = localStorage.getItem("refresh_token");

            await unInterceptedAxiosInstance.get(window.vueConfig.Verify.RefreshTokenEndPoind)
              .then(async (response) => {
                const responseData = response.data

                localStorage.removeItem("token");
                localStorage.removeItem("refresh_token");

                if (responseData.data.error) {
                  this.$reset();
                  location.reload();
                } else {
                  const access_token = `${responseData.data.token_type} ${responseData.data.access_token}`;
                  const refresh_token = `${responseData.data.token_type} ${responseData.data.refresh_token}`;

                  localStorage.setItem("token", access_token);
                  localStorage.setItem("refresh_token", refresh_token);
                  axios.defaults.headers.common["Authorization"] = access_token;

                  this.message = response.message

                  await this.fetchUser();
                }
              })
              .catch(async () => {
                localStorage.removeItem("token");
                localStorage.removeItem("refresh_token");

                this.$reset();
              })
          })
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");

        this.$reset();
      }


    }
  },
});
