<template>
  <vue-final-modal
    v-model="show"
    :classes="'modal-container modal-body ' + modalSize"
    :contentClass="'modal-content modal-body ' + modalSize"
    :click-to-close="false"
    focus-trap
  >
    <login-form
      v-if="visibleComp == 'LoginForm'"
      @close-modal="handleClose"
      @is-loading="handleLoading"
    />
    <registration-form
      v-if="visibleComp == 'RegistrationForm'"
      @close-modal="handleClose"
      @is-loading="handleLoading"
    />
    <otp-form
      v-if="visibleComp == 'OTPForm'"
      @close-modal="handleClose"
      @is-loading="handleLoading"
    />

    <forget-password
      v-if="visibleComp == 'ForgetPassword'"
      @close-modal="handleClose"
      @is-loading="handleLoading"
    />
  </vue-final-modal>
</template>

<script>
import { defineComponent, onBeforeUnmount, ref, watch } from "vue";
import { VueFinalModal } from "vue-final-modal";
import LoginForm from "@/components/Forms/Login.vue";
import RegistrationForm from "@/components/Forms/Registration.vue";
import OtpForm from "@/components/Forms/OTO.vue";
import ForgetPassword from "@/components/Forms/ForgetPassword.vue";

const modalClassObj = {
  LoginForm: "modal-md",
  ForgetPassword: "modal-md",
  RegistrationForm: "modal-lg",
  OTPForm: "modal-sm",
};

export default defineComponent({
  name: "form-modal",
  components: {
    VueFinalModal,
    LoginForm,
    RegistrationForm,
    OtpForm,
    ForgetPassword
  },
  props: {
    showModal: {
      required: true,
      type: Boolean,
    },
    visibleComp: {
      required: true,
      type: String,
    },
  },
  emits: ["closeModal", "isLoading"],
  setup(props, { emit }) {
    const show = ref(false),
      modalSize = ref(null);

    watch(
      () => props.visibleComp,
      (value) => {
        show.value = props.showModal;
        modalSize.value = modalClassObj[value];
      },
      { immediate: true }
    );

    onBeforeUnmount(() => {
      show.value = false;
    });

    const handleClose = (parm) => {
      show.value = parm.showModal;
      emit("closeModal", parm);
    };
    const handleLoading = (param) => {
      emit("isLoading", param);
    };
    return {
      show,
      modalSize,
      handleClose,
      handleLoading,
    };
  },
});
</script>

<style scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.modal-container.modal-sm) {
  left: calc(50% - 180px);
  width: 360px !important;
  max-width: 360px !important;
}

:deep(.modal-container.modal-md) {
  left: calc(50% - 250px);
  width: 500px !important;
}

:deep(.modal-container.modal-lg) {
  left: calc(50% - 400px);
  width: 800px !important;
}

@media(max-width: 500px)  {
:deep(.modal-container.modal-md) {
  left: calc(50% - 180px);
  width: 360px !important;
}

:deep(.modal-container.modal-lg) {
  left: calc(50% - 180px);
  width: 360px !important;
}
}

@media(min-width: 501px) and (max-width: 990px) {
:deep(.modal-container.modal-lg) {
  left: calc(50% - 250px);
  width: 450px !important;
}  
}

:deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 0;
  border: none;
  border-radius: 0.25rem;
  background: #fff;
}
</style>