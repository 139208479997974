<template>
  <header>
    <nav class="navbar navbar-expand-lg navbar-light bg-light site-navbar">
      <div class="container-fluid custom-container">
        <div class="d-flex align-items-center">
          <div class="navbar-sm-btn-container me-2">
            <div
              class="navbar-toggler"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              ref="closeMe"
            >
              <i class="fas fa-bars"></i>
            </div>
          </div>
          <div class="">
            <router-link class="navbar-brand me-2" to="/">
              <img
                src="@/assets/Images/CWCM-logo.svg"
                alt=""
                class="img-fluid"
              />
            </router-link>
            <!-- <p class="text-center logo-tag-line">একসাথে, সহজে, সুলভে!</p> -->
          </div>
        </div>

        <div
          class="collapse navbar-collapse site-sm-navbar"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav mx-auto py-3 py-sm-3 py-md-3 py-lg-0">
            <li class="nav-item mx-lg-2 mx-md-2 my-auto">
              <router-link class="nav-link" to="/" @click="closeMenu">Home</router-link>
            </li>
            <li class="nav-item mx-lg-2 mx-md-2 my-auto">
              <router-link class="nav-link" to="/about" @click="closeMenu">About</router-link>
            </li>
            <li class="nav-item dropdown mx-lg-2 mx-md-2 my-auto">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                >Auction Category</a
              >
              <ul
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <router-link class="dropdown-item" to="/auctions" @click="closeMenu"
                    >All Auctions</router-link
                  >
                </li>

                <li v-for="(commodity, index) in commidities" :key="index">
                  <router-link
                    class="dropdown-item"
                    :to="`/commodity/auctions/${commodity.id}/${commodity.name}`" @click="closeMenu"
                    >{{ commodity.name }}</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item mx-lg-2 mx-md-2 my-auto">
              <router-link class="nav-link" to="/contact" @click="closeMenu">Contact</router-link>
            </li>
            <li class="nav-item mx-lg-2 mx-md-2 my-auto" v-if="isLoggedIn">
              <router-link class="nav-link" to="/profile" @click="closeMenu">Profile</router-link>
            </li>
            <li class="nav-item mx-lg-2 mx-md-2 my-auto d-lg-none" v-if="isLoggedIn">
            <a
              class="nav-link"
              type="submit"
              v-if="isLoggedIn"
              @click="handleLogout"
              >Logout</a
            >
            </li>
          </ul>
        </div>

        <div class="d-flex nav-right-side">
          <div class="search-field my-auto px-2 py-2 d-flex">
            <input
              type="text"
              placeholder="Search"
              class=""
              v-model="search"
              v-on:keyup.enter="onEnter"
            />
            <i class="fa fa-search my-auto ms-1" @click="onEnter"></i>
          </div>
          <div class="d-none d-sm-none d-lg-block">
            <a
              class="btn btn-opaque ms-2"
              type="submit"
              v-if="!isLoggedIn"
              @click="handleComponent('LoginForm')"
              >Login</a
            >
          </div>
          <div class="d-none d-sm-none d-lg-block">
            <a
              class="btn btn-opaque ms-2"
              type="submit"
              v-if="!isLoggedIn"
              @click="handleComponent('RegistrationForm')"
              >Sign up</a
            >
          </div>
          <div class="d-none d-sm-none d-lg-block">
            <a
              class="btn btn-opaque ms-2"
              type="submit"
              v-if="isLoggedIn"
              @click="handleLogout"
              >Logout</a
            >
          </div>
          <div class="d-flex d-lg-none" v-if="isLoggedIn">
            <router-link to="/profile"><i class="fa fa-user mt-1 ms-2"></i></router-link>
          </div>
          <div class="d-flex d-lg-none" v-if="!isLoggedIn">
            <a
              type="submit"
              @click="handleComponent('LoginForm')"
              ><i class="fa fa-user mt-1 ms-2"></i></a
            >
          </div>
        </div>
      </div>
    </nav>
  </header>

  <form-modal
    v-if="showModal"
    :show-modal="showModal"
    :visible-comp="visibleComp"
    :modal-class="modalClass"
    @close-modal="handleOpenModal"
    @is-loading="loadingHandle"
  />
</template>

<script>
import { defineComponent, ref, onMounted, watch } from "vue";

import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/store/useAuth";
import axios from "axios";

export default defineComponent({
  name: "header-section",
  emits: ["isLoading"],
  setup(_, { emit }) {
    const showModal = ref(false),
      auth = useAuthStore(),
      router = useRouter(),
      route = useRoute(),
      visibleComp = ref("LoginForm"),
      modalClass = ref("modal-md"),
      isLoggedIn = ref(false),
      search = ref("");

    const closeMe = ref(null);

    const commidities = ref([]);

    const handleComponent = (parm) => {
      showModal.value = true;
      visibleComp.value = parm;
    };

    watch(
      () => route.query,
      async (query) => {
        try {
          if ("search" in query) {
            search.value = query.search;
          } else {
            search.value = "";
          }
        } catch {
          console.log("404");
        }
      },
      { immediate: true }
    );

    watch(auth, (currentValue) => {
      isLoggedIn.value = currentValue.loggedIn;

      if (!currentValue.loggedIn && router.meta && router.meta.requireAuth) {
        router.push({ name: "home" });
      }
    });

    onMounted(() => {
      isLoggedIn.value = auth.loggedIn;
      // Get all commidities ID and Name
      getCommidities();
    });

    const closeMenu = () => {
      closeMe.value.click();
    }

    const getCommidities = () => {
      axios
        .get(`${window.vueConfig.Product.ItemCommitiesEndPoint}`)
        .then((commidityResponse) => {
          commidities.value = commidityResponse.data.data;
        });
    };

    const handleOpenModal = (parm) => {
      if (!parm.showModal) {
        showModal.value = parm.showModal;
        visibleComp.value = ref(null);

        if (parm.formSubmit) {
          isLoggedIn.value = auth.loggedIn;

          if (!auth.user.account_verified) {
            router.push({ name: "profile" });
          }
        }
      }

      if (parm.showModal) {
        visibleComp.value = parm.nextComp;
      }
    };

    const loadingHandle = (param) => {
      emit("isLoading", param);
    };

    const handleLogout = () => {
      auth.logout().then(() => {
        isLoggedIn.value = false;
        router.push({ name: "home" });
      });
    };

    const onEnter = () => {
      router.push({
        path: "/auctions",
        replace: true,
        query: { search: search.value },
      });
    };

    return {
      showModal,
      visibleComp,
      modalClass,
      isLoggedIn,
      handleComponent,
      handleOpenModal,
      handleLogout,
      loadingHandle,
      commidities,
      search,
      onEnter,
      closeMenu,
      closeMe
    };
  },
});
</script>