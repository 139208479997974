<template>
  <!-- simple toaster -->
  <div class="pop-toaster">
    <div class="position-relative">
        <div class="row align-items-center pop-toaster-info mx-0">
            <div class="pop-toaster-userImage-container col-12 col-sm-3 p-0">
                <img :src="toastData.user.avatar" :alt="toastData.user.name" class="img-fluid user-image">
            </div>
            <div class="col-12 col-sm-7 pop-toaster_userInfo-container">
                <div class="row">
                    <div class="col-12">
                        <p class="mb-0 pop-toaster-userName">
                            <span class="font-600">{{ toastData.user.name }}</span> <span class="font-300">placed a bid.</span>
                        </p>
                        
                        <div class="row align-items-end pb-2">
                            
                            <div class="col-12">
                                <p class="mb-0"><span class="font-300">Price : </span>
                                <span>
                                    <svg version="1.1" id="Layer_1"  x="0px" y="0px" viewBox="0 0 20 20">
                                        <path fill="currentColor" d="M13.6,11.9c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0-1.1-0.2c-0.4-0.2-0.6-0.5-0.8-0.9c-0.1-0.4-0.2-0.8-0.1-1.2
                                                    c0-0.2,0-0.4,0.1-0.5c0.2-0.5,0.5-0.8,1-1C12.7,8.1,13,8,13.4,8c0.5,0,0.9,0.2,1.3,0.5c0.4,0.3,0.6,0.6,0.8,1
                                                    c0.4,0.7,0.5,1.5,0.5,2.3c-0.1,0.8-0.3,1.5-0.7,2.2c-0.8,1.4-1.9,2.4-3.3,3c-0.6,0.2-1.2,0.4-1.8,0.4c-0.4,0-0.8-0.1-1.1-0.2
                                                    c-0.8-0.3-1.4-0.8-1.9-1.5C7.1,15.5,7,15.3,7,15.2c0-0.1,0-0.1,0-0.2c0-1.6,0-3.1,0-4.7c0,0,0-0.1,0-0.1c0,0-0.1,0-0.1,0
                                                    c-0.4,0-0.9,0-1.3,0c-0.1,0-0.1,0-0.1-0.1C4.9,9.6,4.5,9.2,4.1,8.7c0,0,0,0-0.1-0.1c1,0,1.9,0,2.9,0c0,0,0-0.1,0-0.1
                                                    c0-0.9,0-1.9,0-2.8c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.3-0.4C6.4,4.8,6.2,4.8,6,4.7c-0.2,0-0.4,0-0.6,0c-0.1,0-0.1,0.1-0.2,0.1
                                                    c0,0,0,0,0,0C5,4.5,4.9,4.3,4.7,4c0-0.1-0.1-0.2-0.1-0.4c0-0.2,0.1-0.4,0.2-0.5C5.1,3,5.3,2.9,5.5,2.8c0.3-0.1,0.6-0.2,0.9-0.2
                                                    c0.6,0,1,0.2,1.3,0.7C8,3.6,8.1,3.9,8.2,4.2c0.1,0.4,0.2,0.7,0.2,1.1c0,0.1,0,0.1,0,0.2c0,1,0,2,0,3c0,0,0,0.1,0,0.1
                                                    c0,0,0.1,0,0.1,0c0.2,0,0.5,0,0.7,0c0,0,0.1,0,0.1,0c0.4,0.5,0.9,0.9,1.4,1.4c0,0,0,0,0,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0
                                                    c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0
                                                    c0,0,0,0.1,0,0.1c0,1.4,0,2.7,0,4.1c0,0.6,0.3,1.1,1,1.3c0.5,0.1,1,0.1,1.5-0.2c0.4-0.2,0.8-0.4,1.1-0.8c0.5-0.5,1-1.1,1.3-1.8
                                                    c0.1-0.3,0.2-0.6,0.2-0.9C13.6,12,13.6,12,13.6,11.9z"/>
                                    </svg>
                                </span>
                                <span class="font-700">{{ toastData.bid_price }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="row align-items-end pb-2">
                    
                    <div class="col-12">
                        <p class="mb-0"><span class="font-500">{{ toastData.bid_when }}</span>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                width="24" height="24" viewBox="0 0 24 24" 
                                fill="none" 
                                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" 
                                class="feather feather-shield">
                                <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                            </svg>
                        </span>
                        <span class="font-700">Verified</span>
                        </p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
  </div>
  <!-- simple toaster -->
</template>

<script>
import { defineComponent, watch, ref } from "vue";

export default defineComponent({
  name: 'bid-toast',
  props: {
    data: {
      required: true,
      type: Object
    },
  },
  setup(props) {
    const toastData = ref(null)  
    watch(
        () => props.data,
        (value) => {
            console.log(value.bids)
            toastData.value = value.bids
        },
        {immediate: true}
    );

    return {
      toastData
    }
  }
})
</script>