import { createApp } from 'vue'
import { createPinia } from "pinia";
import Cookies from 'js-cookie'
import VueAxios from "vue-axios";
import Toast from "vue-toastification";
import lodash from 'lodash';

import App from '@/App.vue'

import router from '@/router'
// import store from '@/store'
import axios from '@/plugins/axios'

import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.js';

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

// import '@/assets/css/style.css';
import '@/assets/css/style-new.css';
import '@/assets/css/fontawesome.min.css'
import VueCountdown from '@chenfengyuan/vue-countdown';
import FormModal from "@/components/Modal.vue"

import Multiselect from '@vueform/multiselect/src/Multiselect';
import "@vueform/multiselect/themes/default.css";

const vueConfig = require('vue-config')

const configs = require('@/config/api.json')

const app = createApp(App)

app.component('FormModal', FormModal)

app.config.globalProperties.$configs = configs

app.use(createPinia())
app.use(Toast);
app.use(lodash);
app.use(router)
app.use(Cookies)
app.use(VueAxios, axios)
app.use(vueConfig, configs)
app.component('multi-select', Multiselect);
app.component(VueCountdown.name, VueCountdown);

window.vueConfig = configs
window.backendUrl = process.env.VUE_APP_BACKEND_URL

app.mount('#app')

import 'bootstrap/dist/js/bootstrap.js'  
