<template>
  <div class="login p-3">
    <div class="close-reg-modal mb-2 text-end">
      <button
        type="button"
        class="close-reg-button btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        @click="handleClose"
      ></button>
    </div>
    <div class="otpForm pt-3">
      <h5 class="m-0">Mobile phone verification</h5>
      <p class="mobile-text">
        Enter the code we just send on your mobile phone
        <strong class="text-danger">{{ authUser.mobile }}</strong>.
      </p>

      <div>
        <v-otp-input
          ref="otpInput"
          input-classes="otp-input"
          separator="-"
          :num-inputs="4"
          :should-auto-focus="true"
          :is-input-num="true"
          :conditionalClass="['one', 'two', 'three', 'four']"
          :placeholder="['*', '*', '*', '*']"
          @on-change="handleOnChange"
          @on-complete="handleOnComplete"
        />
      </div>

      <div class="text-center mt-5">
        <span class="d-block mobile-text">Don't receive the code?</span
        ><span class="font-weight-bold text-danger cursor">Resend</span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import VOtpInput from "vue3-otp-input";
// import Cookies from 'js-cookie'
import { useAuthStore } from "@/store/useAuth";
import { useErrorStore } from "@/store/useError";

import HelperFunction from "@/common/helpers";

export default defineComponent({
  name: "otp-form",
  emits: ["closeModal", "isLoading"],
  components: { VOtpInput },
  setup(props, { emit }) {
    const loading = ref(false);
    const otpInput = ref(null);
    const auth = useAuthStore();
    const error = useErrorStore();
    const { showToast } = HelperFunction();
    const authUser = computed(() => auth.user);

    const handleClose = () => {
      emit("closeModal", { showModal: false, formSubmit: false });
    };

    const handleOnComplete = (value) => {
      emit("isLoading", { isLoading: true, label: "Verifying OTP ..." });
      loading.value = !loading.value;

      auth
        .verifyOtp({ code: value })
        .then(() => {
          emit("isLoading", false);
          loading.value = !loading.value;

          emit("closeModal", {
            status: 200,
            message: "Success",
            showModal: false,
            formSubmit: true,
          });
        })
        .catch(() => {
          emit("isLoading", false);
          loading.value = !loading.value;

          showToast({ status: error.status, data: { message: error.message } });
        });
    };

    // const handleOnChange = (value) => {
    //   console.log('OTP changed: ', value);
    // };

    const clearInput = () => {
      otpInput.value.clearInput();
    };

    return {
      handleOnComplete,
      clearInput,
      handleClose,
      authUser
    };
  },
});
</script>

<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  text-align: center;
}
/* Background colour of an input field with value */
.otp-input.is-complete {
  background-color: #e4e4e4;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
</style>