<template>
  <Header @is-loading="isLoading"> </Header>
  <main>
    
    <!-- bannerSection -->
    <section class="site-banner-section" v-show="currentRoute === 'home'">
      <div class="container-fluid custom-container">
        <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row align-items-center">
                <div class="col-12 col-sm-12 col-md-6 banner-left">
                  <div class="d-flex justify-content-center justify-content-sm-center justify-content-md-end flex-grow-0 flex-shrink-0">
                    <img src="@/assets/Images/Chamak-Services-Infographic-bangla.svg" alt="" class="img-fluid">
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 banner-right text-lg-start text-md-start  text-sm-center text-center">
                  <h1 class="banner-title-01 mb-0 font-fl-title font-sz-90 font-700">
                    ডিজিটাল আড়ৎ
                  </h1>
                  <p class="banner-title-02  font-sz-24 mb-0">
                    কৃষি পণ্য কিনতে আজকেই বিড করুন!
                  </p>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-center">
                <div class="col-12 col-sm-12 col-md-6 banner-left">
                  <div class="d-flex justify-content-center justify-content-sm-center justify-content-md-end flex-grow-0 flex-shrink-0">
                    <img src="@/assets/Images/Chamak-Services-Infographic-english.svg" alt="" class="img-fluid">
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 banner-right d-flex flex-column text-lg-start text-md-start  text-sm-center text-center">
                  <h1 class="banner-title-01 mb-0 font-fl-title font-sz-90 font-700">
                    Digital Arot
                  </h1>
                  <p class="banner-title-02 font-sz-24 mb-0">
                    Bid Today to Get Your Agri-products!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- bannerSection -->

    <router-view @is-loading="isLoading" />
  </main>

  <Footer />

  <loading :show="loderData.loading" :label="loderData.loading_label" />
</template>

<script>
import { computed, defineComponent, onBeforeMount, ref } from "vue";
import loading from "vue-full-loading";
import Pusher from "pusher-js";

import { useAuthStore } from "@/store/useAuth";

import Header from "@/views/layouts/common/Header.vue";
import Footer from "@/views/layouts/common/Footer.vue";
import HelperFunction from "@/common/helpers";
import { useRoute } from 'vue-router';

export default defineComponent({
  name: "main-index",
  components: {
    Header,
    Footer,
    loading,
  },
  setup() {
    let pusher;
    const { showToast } = HelperFunction();
    const auth = useAuthStore();
    const user = computed(() => auth.user);

    const loderData = ref({
      loading: false,
      loading_label: "Loading ... ",
    });
    const pusherData = ref(null);

    const currentRoute = computed( () => {
      return useRoute().name;
    });

    onBeforeMount(() => {
      Pusher.logToConsole = true;

      pusher = new Pusher(`${process.env.VUE_APP_PUSHER_KEY}`, {
        cluster: `${process.env.VUE_APP_PUSHER_CLUSTER}`,
      });

      const notification = pusher.subscribe("notification");
      notification.bind("message", (response) => {
        if (
          user.value == null ||
          user.value.user_id != response.bids.user.user_id
        ) {
          showToast(response, true);
        }
      });
    });

    const isLoading = (params) => {
      loderData.value.loading =
        params.isLoading == null ? params : params.isLoading;
      loderData.value.loading_label =
        params.label == null ? "Loading..." : params.label;
      if (params == true || params.isLoading == true) {
        setTimeout(() => {
          loderData.value.loading = false;
        }, 10000);
      }
    };

    return {
      loderData,
      pusherData,
      isLoading,
      user,
      currentRoute
    };
  },
});
</script>